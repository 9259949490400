<template>
  <div class="account bg-white px-4 pt-4 rounded-lg pb-10">
    <div class="grid justify-start grid-flow-col gap-4 items-center">
      <div class="w-20 h-20 rounded-full bg-white">
        <User color="neutral-100" height="80" width="80" v-if="userProfile?.student?.photo === '' || userProfile?.student?.photo === undefined" />
        <div v-else class="w-full h-full rounded-full bg-no-repeat bg-cover profpic" :style="`background-image: url(${userProfile?.student?.photo})`"></div>
      </div>
      <div class="flex flex-col">
        <p class="font-semibold text-lg">{{ userProfile?.name }}</p>
        <p class="text-sm font-medium text-neutral-500">{{ userProfile?.email }}</p>
      </div>
    </div>
    <ul class="mt-8">
      <li></li>
      <li>
        <router-link
          to="/user/my-classes"
          class="rounded-lg font-semibold hover:text-yellow transition-all duration-200 grid justify-start grid-flow-col gap-2 items-center cursor-pointer hover:bg-yellow-100 py-2 px-3"
        >
          <div class="w-7 flex justify-center">
            <Bootcamp width="24" height="24" />
          </div>
          <span>My Class</span>
        </router-link>
      </li>
      <li class="border-t border-neutral-100 mt-4 pt-4">
        <router-link
          to="/user/profile"
          class="rounded-lg transition-all font-semibold duration-200 grid justify-start grid-flow-col gap-2 items-center cursor-pointer hover:bg-yellow-100 py-2 px-3 hover:text-yellow"
          ><div class="w-7 flex justify-center">
            <User width="24" height="24" />
          </div>
          Profile
        </router-link>
      </li>
      <li>
        <router-link
          to="/user/change-password"
          class="rounded-lg transition-all duration-200 grid justify-start grid-flow-col gap-2 font-semibold text-neutral-500 items-center cursor-pointer hover:bg-yellow-100 py-2 px-3 hover:text-yellow"
          ><div class="w-7 flex justify-center">
            <Lock />
          </div>
          Change Password
        </router-link>
      </li>
      <li class="border-t border-neutral-100 mt-4 pt-4">
        <div
          class="rounded-lg transition-all duration-200 grid justify-start grid-flow-col gap-2 font-semibold text-system-error items-center cursor-pointer hover:bg-yellow-100 py-2 px-3 hover:text-yellow"
          @click="doLogout"
        >
          <div class="w-7 flex justify-center pl-1">
            <Exit height="24" width="24" />
          </div>
          Logout
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import VueCookies from 'vue-cookies'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    User: () => import(/* webpackChunkName: "icons" */ '@/components/icons/User'),
    Bootcamp: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Bootcamp'),
    Lock: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Lock'),
    Exit: () => import(/* webpackChunkName: "header-icon" */ '@/components/icons/Exit')
  },
  created() {
    if (!this.isMobile) this.$router.push('/user/profile')
  },
  mounted() {
    this.getUserInfo()
  },
  computed: {
    ...mapGetters('prakerja', ['userProfile']),
    ...mapGetters('common', ['isMobile'])
  },
  methods: {
    ...mapActions('prakerja', ['getUserProfile']),
    ...mapActions('auth', ['removeUserData', 'getCurrentUser']),
    getUserInfo() {
      this.getUserProfile().then((res) => {
        localStorage.setItem('user_name', res.name)
        this.getCurrentUser()
      })
    },
    doLogout() {
      VueCookies.remove('token')
      const cleanUserData = new Promise((resolve, reject) => {
        resolve(this.removeUserData())
      })
      cleanUserData.then(() => {
        this.$router.push({ name: 'Home' })
      })
    }
  }
}
</script>
